import Cookies from 'js-cookie'

export const getUserId = () => Cookies.get('__app__userId__')
export const setUserId = (value: string) => {
  Cookies.set('__app__userId__', value, { domain: 'ijianshui.com', path: '', expires: 365 })
}

export const getHomePageCouponPop = () => sessionStorage['__app__homepage__coupon__']
export const setHomePageCouponPop = (value: string) => {
  if (!value) {
    return
  }
  sessionStorage['__app__homepage__coupon__'] = value
}
export const delHomePageCouponPop = () => sessionStorage.removeItem('__app__homepage__coupon__')

export const getXNameTest = () => sessionStorage['__x_name_test__']
export const setXNameTest = (value: string) => {
  if (!value) {
    return
  }
  sessionStorage['__x_name_test__'] = value
}
export const delXNameTest = () => sessionStorage.removeItem('__x_name_test__')

export const getInfoInputAnimationEnd = () => sessionStorage['__x_name_test_input_animation_end__']
export const setInfoInputAnimationEnd = (value: string) => {
  if (!value) {
    return
  }
  sessionStorage['__x_name_test_input_animation_end__'] = value
}
export const delInfoInputAnimationEnd = () =>
  sessionStorage.removeItem('__x_name_test_input_animation_end__')
