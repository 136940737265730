export const unlockdata = (male: string, female: string) => [
  {
    title: `第一：${female}小姐的性格解析`,
  },
  {
    title: `第二：${male}先生的性格解析`,
  },
  {
    title: `第三：${male}先生的运势`,
  },
  {
    title: `第四：${female}小姐的运势`,
  },
  {
    title: '第五：爱情危机',
    data: ['两⼈相处时会出现哪些危机？', '引发危机的原因都有什么？', '如何破解危机，守护爱情？'],
  },
  {
    title: '第六：爱情建议',
    data: [
      '你们的恋爱婚姻稳定情况',
      '会屈就于你还是你只有听从的份？',
      '相处之中你们是否更具默契？',
    ],
  },
]
