import cn from 'classnames'
import { useEffect } from 'react'
import { useSetState } from 'react-use'
import { getInfoInputAnimationEnd, setInfoInputAnimationEnd } from '../../../../utils/storage'
import { sleep } from '../../../../utils/time'
import './style.scss'

interface IState {
  animationTitle: string
  processing: boolean
  steps: 'step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'end'
}

interface IProps {
  onStepAnimationEnd: () => void
}

export default function Steps(props: IProps) {
  const stepsTitle = {
    step1: '正在录入双方姓名',
    step2: '正在分析双方命格',
    step3: '正在分析感情特质',
    step4: '正在分析配对指数',
    step5: '正在生成分析报告',
    end: '',
  }

  const [state, setState] = useSetState<IState>({
    steps: 'step1',
    animationTitle: '',
    processing: false,
  })

  const stepByStep = async (step: 'step1' | 'step2' | 'step3' | 'step4' | 'step5' | 'end') => {
    await sleep(2000)

    setState({
      processing: false,
    })

    await sleep(100)

    setState({
      animationTitle: stepsTitle[step],
      processing: true,
    })
  }

  const animationSteps = async () => {
    setState({
      animationTitle: stepsTitle['step1'],
      processing: true,
    })

    await stepByStep('step2')
    await stepByStep('step3')
    await stepByStep('step4')
    await stepByStep('step5')

    await sleep(1500)

    setState({
      animationTitle: stepsTitle['end'],
      processing: false,
    })

    setInfoInputAnimationEnd('end')

    props.onStepAnimationEnd()
  }

  useEffect(() => {
    // 动画
    if (getInfoInputAnimationEnd() !== 'end') {
      animationSteps()
    } else {
      props.onStepAnimationEnd()
    }
  }, [])

  return (
    <div className="com-steps">
      <div className="process-animation">
        <div className="title">{state.animationTitle}</div>
        <div
          className={cn('process', {
            start: state.processing,
          })}
        >
          {state.processing && <div className="bar" />}
        </div>
      </div>
    </div>
  )
}
