import { Search } from 'auto-libs'
import { Alert, Button, Dialog, Layout, Radio, Toast } from 'auto-ui'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import FeedBack from '../../components/feedback'
import { domain } from '../../utils/config'
import http from '../../utils/http'
import { sleep } from '../../utils/time'
import CouponPop from './components/coupon-pop/index'
import FakeComment from './components/fake-comment-lite'
import Banner from './imgs/banner.jpg'
import './style.scss'
import {
  getHomePageCouponPop,
  getUserId,
  setHomePageCouponPop,
  setUserId,
  delXNameTest,
  delInfoInputAnimationEnd,
} from '../../utils/storage'

interface IState {
  male: string
  female: string
  checkedAgreement: boolean
  popVisible: boolean
  couponPopVisible: boolean
  popRuleVisible: boolean
  orderNo: string
}

const PageIndex = () => {
  const navigateHook = useNavigate()
  const cb = Search.get('callback') || ''
  const channel = Search.get('channel') || ''
  const orderNo = Search.get('orderNo') || ''

  const [state, setState] = useSetState<IState>({
    male: '',
    female: '',
    checkedAgreement: true,
    popVisible: false,
    couponPopVisible: false,
    popRuleVisible: false,
    orderNo,
  })

  const onInputChange = (e: any) => {
    const value = e.target.value
    const id = e.target.id

    setState({
      [id]: value,
    })
  }

  const onAgreementClick = () => {
    setState({
      checkedAgreement: !state.checkedAgreement,
    })
  }

  const isChinese = (name: string) => {
    const reg = /^[\u4E00-\u9FA5]{2,4}$/
    return reg.test(name)
  }

  const onSubmit = async () => {
    try {
      const { male, female } = state
      if (!male.trim() || !female.trim()) {
        Toast('请先填写双方姓名')
        return
      }

      // if (!state.checkedAgreement) {
      //   Toast('请先同意协议')
      //   return
      // }

      if (!isChinese(male) || !isChinese(female)) {
        Toast('请填写2-4个字中文汉字')
        return
      }

      delInfoInputAnimationEnd()

      const result: any = await http.request({
        url: '/order/create',
        method: 'POST',
        data: {
          bName: male,
          gName: female,
          adType: channel ? channel : 'kuaishou',
          adParams: cb,
          channel: channel,
          productType: '6',
        },
      })

      const { code, body } = result
      if (code !== '00000000') {
        Toast('请求失败，请再试')
        return
      }

      const { orderId } = body

      setState({
        orderNo: orderId,
      })

      navigateHook(`/?orderNo=${orderId}`, { replace: true })

      await sleep(100)

      delXNameTest()
      setHomePageCouponPop('true')
      navigateHook(`/pay?male=${male}&female=${female}&orderNo=${orderId}`)
    } catch (e) {
      Toast('请求失败，请再试')
    }
  }

  const fetchUserId = async () => {
    try {
      const result: any = await http.request({
        url: '/user/getNewUserId',
        method: 'POST',
        data: {},
      })

      const { code, body } = result

      if (code !== '00000000') {
        return
      }

      const { userId } = body

      setUserId(userId)
      statistics(userId)
    } catch (error) {}
  }

  const statistics = async (userId: string) => {
    const params: any = { userId }

    if (channel) {
      params.channel = channel
    }

    await http.request({
      url: '/statistics/submit',
      method: 'POST',
      data: params,
    })
  }

  const onOrderClick = () => {
    window.location.href = domain + '/order'
  }

  const onCouponPopClose = () => {
    setState({
      couponPopVisible: false,
    })
  }

  const onRuleClick = () => {
    setState({
      popRuleVisible: true,
    })
  }

  const onContactClick = () => {
    Alert({
      desc: '售后服务请咨询电话：17898826771',
      btns: [
        {
          name: '确定',
          style: { background: '#f13b3b' },
        },
      ],
    })
  }

  useEffect(() => {
    if (getHomePageCouponPop() === 'true') {
      setState({
        couponPopVisible: true,
      })
    }

    const userId = getUserId()
    if (userId) {
      statistics(userId)
      return
    }

    fetchUserId()
  }, [])

  return (
    <Layout className="page-index" useBottomSafeArea={false}>
      <Layout.Header
        title="姓名测试"
        className="header"
        addonAfter={
          <Button hollow mini type="danger" className="order" onClick={onOrderClick}>
            我的订单
          </Button>
        }
      />
      <Layout.Body>
        <div className="banner">
          <div className="marquee">
            <div className="inner">
              本网页数据严格遵循国家法律法规和用户隐私协议对您的信息进行全方位保护
            </div>
          </div>
          <img className="img" src={Banner} alt="" />

          {channel === 'xpc6$67' && (
            <div className="tools">
              <div className="tool rule" onClick={onRuleClick}>
                规则
              </div>
              <div className="tool contact" onClick={onContactClick}>
                客服
              </div>
            </div>
          )}
        </div>

        <div className="form">
          <div className="input-wrapper">
            <div className="item male">
              <div className="icon" />
              <div className="label">男方姓名</div>
              <div className="input">
                <input
                  id="male"
                  placeholder="请输入姓名"
                  value={state.male}
                  onChange={onInputChange}
                  maxLength={4}
                />
              </div>
            </div>
            <div className="item female">
              <div className="icon" />
              <div className="label">女方姓名</div>
              <div className="input">
                <input
                  id="female"
                  placeholder="请输入姓名"
                  value={state.female}
                  onChange={onInputChange}
                  maxLength={4}
                />
              </div>
            </div>
          </div>

          <div className="agreement" onClick={onAgreementClick}>
            <Radio checked={state.checkedAgreement} className="checkbox" />
            <div className="text">
              您的信息仅用于生成分析报告，
              <a
                onClick={(e) => {
                  setState({
                    popVisible: true,
                  })
                  e.stopPropagation()
                }}
              >
                查看隐私协议
              </a>
              ，提交即视为您已同意《隐私协议》
            </div>
          </div>
        </div>

        <div className="btns">
          <Button className="submit" onClick={onSubmit}>
            立即领取报告
          </Button>

          <div className="tip">
            已有<em>499006</em>人解领取报告，好评率高达<em>99.76%</em>
          </div>

          <FakeComment />
        </div>

        <div className="more">
          <h2>资深大师 匠心巨制</h2>
          <div className="cx" />
          <div className="company">上海简水网络科技有限公司</div>
          <div className="tip-warning">需付费后方可查看结果，结果纯属娱乐仅供参考</div>
          {channel === 'xpc6$67' && <div className="contact">客服电话：17898826771</div>}
        </div>

        <FeedBack orderNo={state.orderNo} />
      </Layout.Body>
      <Dialog
        visible={state.popVisible}
        height="80%"
        title="个人隐私协议"
        onCloseClick={() =>
          setState({
            popVisible: false,
          })
        }
        onMaskClick={() =>
          setState({
            popVisible: false,
          })
        }
      >
        <Dialog.Scroller>
          <div className="content">
            <div className="text">
              <p>尊敬的用户，欢迎阅读本协议：</p>
              <p>
                上海简水网络科技有限公司依据本协议的规定提供服务，本协议具有合同效力。您必须完全同意以下所有条款，才能保证享受到更好的上海简水网络科技有限公司服务。您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
              </p>
              <p>
                用户在申请上海简水网络科技有限公司服务过程中，需要填写一些必要的个人信息，为了更好的为用户服务，请保证提供的这些个人信息的真实、准确、合法、有效并注意及时更新。
                <strong>
                  若因填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。如因用户提供的个人资料不实或不准确，给用户自身造成任何性质的损失，均由用户自行承担。
                </strong>
              </p>
              <p>
                保护用户个人信息是上海简水网络科技有限公司的一项基本原则，上海简水网络科技有限公司运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。
                <strong>
                  未经用户许可上海简水网络科技有限公司不会向第三方（上海简水网络科技有限公司控股或关联、运营合作单位除外）公开、透露用户个人信息，但由于政府要求、法律政策需要等原因除外。
                </strong>
              </p>
              <p>
                在用户发送信息的过程中和本网站收到信息后，
                <strong>
                  本网站将遵守行业通用的标准来保护用户的私人信息。但是任何通过因特网发送的信息或电子版本的存储方式都无法确保100%的安全性。因此，本网站会尽力使用商业上可接受的方式来保护用户的个人信息，但不对用户信息的安全作任何担保。
                </strong>
              </p>
              <p>
                此外，您已知悉并同意：
                <strong>
                  在现行法律法规允许的范围内，上海简水网络科技有限公司可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：在网页或者app平台中向您展示或提供广告和促销资料，向您通告或推荐服务或产品信息，使用电子邮件，短信等方式推送其他此类根据您使用上海简水网络科技有限公司服务或产品的情况所认为您可能会感兴趣的信息。
                </strong>
              </p>
              <p>
                本网站有权在必要时修改服务条例，
                <strong>
                  本网站的服务条例一旦发生变动，将会在本网站的重要页面上提示修改内容，用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。
                </strong>
                根据客观情况及经营方针的变化，本网站有中断或停止服务的权利，用户对此表示理解并完全认同。
              </p>
              <p>上海简水网络科技有限公司保留对本协议的最终解释权。</p>
            </div>
          </div>
        </Dialog.Scroller>
      </Dialog>

      <Dialog
        visible={state.popRuleVisible}
        height="80%"
        title="活动规则"
        onCloseClick={() =>
          setState({
            popRuleVisible: false,
          })
        }
        onMaskClick={() =>
          setState({
            popRuleVisible: false,
          })
        }
      >
        <Dialog.Scroller>
          <div className="content">
            <div className="text">
              <p>
                <strong>活动主题:</strong>
              </p>
              <p>姓名配对抽大奖，好礼等你来</p>
              <p>
                <strong>活动时间:</strong>
              </p>
              <p>活动当日至2023年12月31日</p>
              <p>
                <strong>活动对象:</strong>
              </p>
              <p>参与姓名配对用户</p>
              <p>
                <strong>活动内容:</strong>
              </p>
              <p>
                活动期间，凡参与姓名配对用户，领取一次 9.9
                元以上的报告后即可通过报告算法实时抽奖，随机抽取活动奖品，每位用户有次中奖机会。
              </p>
              <p>
                <strong>活动流程:</strong>
              </p>
              <p>
                填写姓名-支付-查看结果(如满 99
                分)会有机会出现点击领取按钮弹出客服联系方式-发送订单号联系方式和地址-官方邮寄产品。
              </p>
              <p>
                <strong>活动规则:</strong>
              </p>
              <p>1.本次活动交易数据以后台系统记录为准。</p>
              <p>2.活动奖品每月更新，以页面活动规则公布内容为准。</p>
              <p>
                3 中奖用户请于中奖后 30 日内填写相关个人信息，以便我们为您派送奖品;超过 30
                日仍未填写个人信息，导致无法派送奖品，视为自动放弃该奖品。
              </p>
              <p>
                4.我们将于用户获奖后 20
                个工作日内，向获奖客户派送所获奖品实物礼品将寄送至客户所填写的收货地址，请客户实名签收:虚拟礼品将发送至客户填写的手机号码
              </p>
              <p>
                <strong>活动声明:</strong>
              </p>
              <p>1.凡参与本次活动即视为同意活动规则所述的各项规定</p>
              <p>2.如遇不可抗力因素，活动主办方拥有取消本次活动的权利</p>
              <p>
                3.为确保活动的公平性，一人只能一次参加本次活动，相同 ip
                相同支付账号相同微信等可判定用户行为的均属于同一用户
              </p>
              <p>
                4.在法律允许的范围内我们将保留修改本次活动条款、延迟或提前终止本活动而无需事先通知权利
              </p>
            </div>
          </div>
        </Dialog.Scroller>
      </Dialog>

      <CouponPop
        visible={state.couponPopVisible}
        onPopClose={onCouponPopClose}
        orderNo={state.orderNo}
      />
    </Layout>
  )
}

export default PageIndex
