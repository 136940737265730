import axios from 'axios'
import cn from 'classnames'
import cnchar from 'cnchar'
import draw from 'cnchar-draw'
import { useEffect } from 'react'
import { useSetState } from 'react-use'
import { cncharApiUrl } from '../../../../utils/http'
import { sleep } from '../../../../utils/time'
import './style.scss'

draw.setResourceBase(cncharApiUrl)

interface IProps {
  letters: string
  type: 'male' | 'female' // 全局唯一
  style?: {
    color?: string
  }
}

interface IState {
  [x: string]: any
}

export default function LetterSpell(props: IProps) {
  const { letters, type, style } = props

  const [state, setState] = useSetState<IState>({})

  const cnCharDraw = async () => {
    const promiseAllRequest = letters.split('').map((item: string, index: number) => {
      return function () {
        axios.get(`${cncharApiUrl}${item}.json`).then(() => {
          setState({
            [`${type}-${item}`]: true,
          })
          cnchar.draw(item, {
            el: `#cnchar-${type}-${index}`,
            type: cnchar.draw.TYPE.ANIMATION,
            // @ts-ignore
            test: { showHintAfterMisses: false },
            style: {
              strokeColor: style?.color || '',
            },
            animation: {
              strokeAnimationSpeed: 1,
              delayBetweenStrokes: 1,
              autoAnimate: true,
            },
          })
        })
      }
    })

    for await (const n of promiseAllRequest) {
      n()
      await sleep(2000)
    }
  }

  useEffect(() => {
    cnCharDraw()
  }, [])

  return (
    <div className="com-letter-spell">
      <div className={cn('letters', type)}>
        {letters.split('').map((item: string, index: number) => (
          <div className="letter" key={index}>
            <div
              className={cn('pinyin', {
                show: state[`${type}-${item}`],
              })}
            >
              {item.spell()}
            </div>
            <div id={`cnchar-${type}-${index}`} className="cnchar" />
          </div>
        ))}
      </div>
    </div>
  )
}
