import { Search } from 'auto-libs'
import { Button, Layout, Loading, Toast } from 'auto-ui'
import cn from 'classnames'
import { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import { useSetState } from 'react-use'
import Title from '../../components/title'
import { wxPay } from '../../utils/pay'
import { getCountDown, sleep } from '../../utils/time'
import FakeBubble from './components/fake-buble'
import FakeComment from './components/fake-comment'
import LetterSpell from './components/letter-spell'
import ResultLock from './components/result-lock'
import Steps from './components/steps'
import { unlockdata } from './data'
import Banner from './imgs/banner.png'
import './style.scss'

interface IState {
  isCountUpEnd: boolean
  isAnimationEnd: boolean
  isShowFixedSubmit: boolean
}

const PagePay = () => {
  const [time, setTime] = useState(60 * 5 - 1)

  // const [alipayForm, setAlipayForm] = useState<any>(null)
  const { male, female, orderNo } = Search.parse()
  let timer: any = null

  const [state, setState] = useSetState<IState>({
    isCountUpEnd: false,
    isAnimationEnd: false,
    isShowFixedSubmit: false,
  })

  const countdown = (time: number, f: any) => {
    let _time = time
    timer = setTimeout(() => {
      --_time
      if (_time === 0) {
        clearTimeout(_time)
        return
      }
      f(_time)
      countdown(_time, f)
    }, 1000)
  }

  const onWechatPay = async (discount?: string) => {
    try {
      Loading()
      const result = await wxPay(orderNo, discount)
      window.location.href = result
    } catch (error: any) {
      Toast(error.message || '系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  // const onAlipay = async (discount?: string) => {
  //   try {
  //     Loading()
  //     const result = await aliPay(orderNo, discount)

  //     setAlipayForm(result)

  //     setTimeout(() => {
  //       document.forms[0].submit()
  //     }, 100)
  //   } catch (error) {
  //     Toast('系统异常，请重试')
  //   } finally {
  //     Loading.hide()
  //   }
  // }

  const onStepAnimationEnd = async () => {
    setState({
      isAnimationEnd: true,
    })

    await sleep(100)

    document.getElementsByClassName('x-app-body')[0].scrollTo({ top: 520, behavior: 'smooth' })
  }

  const onScroll = (e: any) => {
    if (e.target.scrollTop >= 800) {
      setState({
        isShowFixedSubmit: true,
      })
    } else {
      setState({
        isShowFixedSubmit: false,
      })
    }
  }

  useEffect(() => {
    countdown(60 * 5 - 1, setTime)

    return () => {
      timer && clearTimeout(timer)
    }
  }, [])

  return (
    <Layout className="page-pay">
      <Layout.Body className="body" onScroll={onScroll}>
        <div className="banner">
          <img src={Banner} />
        </div>

        <div className="names">
          <LetterSpell letters={male} type="male" style={{ color: 'rgba(43, 55, 198, 1)' }} />
          <LetterSpell letters={female} type="female" style={{ color: 'rgb(208, 28, 28)' }} />

          <div className="scores">
            <div className="circle circle1">过去</div>
            <div className="circle circle2">现在</div>
            <div className="circle circle3">未来</div>
            <div className="star">
              <div className="score">
                {state.isCountUpEnd ? (
                  <span>??</span>
                ) : (
                  <CountUp
                    delay={1}
                    start={60}
                    end={99}
                    duration={5}
                    onEnd={() => setState({ isCountUpEnd: true })}
                  />
                )}
              </div>
              <div className="txt">配对指数</div>
            </div>
            <div className="tip">平生愿得一人心 相爱白头不相离</div>
          </div>
        </div>

        {!state.isAnimationEnd ? (
          <Steps onStepAnimationEnd={onStepAnimationEnd} />
        ) : (
          <>
            <Title title="您的专属报告已生成" />
            <div className="price">
              <div className="discount">
                <span className="original">原价69.9元</span> <span>限时优惠倒计时</span>
              </div>
              <div className="current">
                <span>吉时特价：</span>
                <em>￥9.9</em>
                <div className="countdown">{getCountDown(time)}</div>
              </div>
            </div>

            <div className="pay-button">
              <Button className="btn wechat" onClick={() => onWechatPay()}>
                <span className="icon" />
                微信支付领取报告
              </Button>
              {/* <Button className="btn alipay" onClick={() => onAlipay()}>
                <span className="icon" />
                支付宝支付领取报告
              </Button> */}
            </div>

            <div className="tip">
              已有<em>499006</em>人进行了测试分析，帮助他们找到<em>美满恋爱婚姻，99.76%</em>
              的用户认为测算结果对他们的婚姻生活产生了巨大帮助。
            </div>

            <div className="pay-tip">支付完成将为您解锁其余六项重要内容</div>

            <div className="result-list lock">
              {unlockdata(male, female).map((item, key) => {
                return <ResultLock title={item.title} key={key} data={item.data} />
              })}
            </div>

            <div className="comment">
              <FakeComment />
            </div>

            <FakeBubble />

            {state.isShowFixedSubmit && (
              <div className={cn('fixed-btn')}>
                <Button className="fixed-submit" onClick={() => onWechatPay()}>
                  立即解锁配对报告
                </Button>
              </div>
            )}
          </>
        )}

        <div className="more">
          <h2>资深大师 匠心巨制</h2>
          <div className="company">上海简水网络科技有限公司</div>
          <div className="cx" />
        </div>
      </Layout.Body>

      {/* <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: alipayForm }}
      /> */}
    </Layout>
  )
}

export default PagePay
