import React from 'react'
import './style.scss'

export default function FakeCommentLite() {
  const data = [
    {
      time: '5秒前',
      province: '江苏',
      name: '*斌和赵*雅',
      score: '99分好评',
    },
    {
      time: '15秒前',
      province: '广东',
      name: '刘*云和曹*婷',
      score: '98分好评',
    },
    {
      time: '37秒前',
      province: '上海',
      name: '杨*元和李*儿',
      score: '100分好评',
    },
    {
      time: '45秒前',
      province: '云南',
      name: '许*银和赵*萍',
      score: '98分好评',
    },
    {
      time: '48秒前',
      province: '杭州',
      name: '付*成和张*芳',
      score: '99分好评',
    },
    {
      time: '1分钟前',
      province: '云南',
      name: '刘*飞和李*',
      score: '100分好评',
    },
    {
      time: '1分钟前',
      province: '贵州',
      name: '张*浩和何*霞',
      score: '100分好评',
    },
    {
      time: '1分钟前',
      province: '新疆',
      name: '沈*涛和姜*清',
      score: '98分好评',
    },
    {
      time: '1分钟前',
      province: '上海',
      name: '赵*达和张*',
      score: '99分好评',
    },
    {
      time: '2分钟前',
      province: '天津',
      name: '李*和林*燕',
      score: '100分好评',
    },
    {
      time: '2分钟前',
      province: '安徽',
      name: '张*剑和潘*敏',
      score: '100分好评',
    },
    {
      time: '3分钟前',
      province: '湖南',
      name: '李*育和沈*',
      score: '98分好评',
    },
    {
      time: '3分钟前',
      province: '湖北',
      name: '*敏和沈*',
      score: '99分好评',
    },
    {
      time: '3分钟前',
      province: '河南',
      name: '周*超和彭*',
      score: '97分好评',
    },
    {
      time: '3分钟前',
      province: '河北',
      name: '*超和阮*虹',
      score: '99分好评',
    },
    {
      time: '4分钟前',
      province: '浙江',
      name: '张*超和李*静',
      score: '100分好评',
    },
    {
      time: '4分钟前',
      province: '江苏',
      name: '张*澄和申*因',
      score: '99分好评',
    },
    {
      time: '4分钟前',
      province: '陕西',
      name: '李*和孙*文',
      score: '96分好评',
    },
    {
      time: '4分钟前',
      province: '陕西',
      name: '邵*宏和王*',
      score: '100分好评',
    },
    {
      time: '5分钟前',
      province: '辽宁',
      name: '周*和王*凤',
      score: '99分好评',
    },
    {
      time: '5分钟前',
      province: '新疆',
      name: '陈*予和王*琪',
      score: '100分好评',
    },
    {
      time: '5分钟前',
      province: '云南',
      name: '王*旭和吴*楚',
      score: '100分好评',
    },
    {
      time: '5分钟前',
      province: '广州',
      name: '赵*和吴*',
      score: '98分好评',
    },
    {
      time: '5分钟前',
      province: '河南',
      name: '*超和李*楠',
      score: '99分好评',
    },
  ]

  return (
    <div className="com-fake-comment-lite">
      <div className="marquee">
        <div className="inner">
          {data.map((item: any, index: number) => (
            <div className="item" key={index}>
              <span>{item.time}</span>
              <span>{item.province}</span>
              <span>{item.name}</span>
              <span>{item.score}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
