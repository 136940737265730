import React from 'react'
import './style.scss'

interface IProps {
  title: string
  data?: any
}

const ResultLock: React.FC<IProps> = (props) => {
  const { title, data } = props
  return (
    <div className="com-page-pay-result-lock">
      <div className="title">
        {title}
        <span className="arrow" />
      </div>
      {data && (
        <div className="content" dangerouslySetInnerHTML={{ __html: data }}>
          {/* <div className="left">
            {data.map((item: any, index: number) => {
              return <div key={index} dangerouslySetInnerHTML={{ __html: item }} />
            })}
          </div> */}
        </div>
      )}
    </div>
  )
}
export default ResultLock
