import { Search } from 'auto-libs'
import { Alert, Button, Layout, Loading, Toast } from 'auto-ui'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import FeedBack from '../../components/feedback'
import Title from '../../components/title'
import http from '../../utils/http'
import { aliPay, wxPay } from '../../utils/pay'
import { delHomePageCouponPop, setXNameTest } from '../../utils/storage'
import { sleep } from '../../utils/time'
import ClassifyHeader from './components/classify-header'
import Result from './components/result'
import { lockdata } from './data'
import AdHehun from './imgs/ad-hehun.png'
import AdQinggan from './imgs/ad-qinggan.png'
import AdYinyuan from './imgs/ad-yinyuan.png'
import Banner from './imgs/banner.png'
import './style.scss'

export interface IState {
  current: number
  score: number
  loading: boolean
  errorMsg: string
}

const PageIndex = () => {
  const navigateHook = useNavigate()
  const [resultData, setResultData] = useState<any>(lockdata)
  const [alipayForm, setAlipayForm] = useState<any>(null)
  const [bName, setBName] = useState('')
  const [gName, setGName] = useState('')
  const orderId = Search.get('orderNo') || ''

  const [state, setState] = useSetState<IState>({
    current: 1,
    score: 0,
    loading: false,
    errorMsg: '',
  })

  let timer: any = null
  let payWay = 'WXPAY'
  let alertCloseHandler: any = null

  const onBackHome = () => {
    navigateHook('/', { replace: true })
  }

  const fetchMatchResult = async () => {
    try {
      if (!orderId) {
        Toast('地址不正确，请回首页重新测试')
        return
      }

      setState({
        loading: true,
      })

      await sleep(1500)

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId,
        },
      })

      let { payMethod, status } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod
        checkPayStatus()
        return
      }

      const result: any = await http.request({
        url: 'result/queryXmpd2',
        method: 'POST',
        data: {
          orderId,
        },
      })

      const { body } = result
      const { bName, gName, score, bxg, gxg, bsm, gsm, aqwj, aqjy } = body
      const resultList = [bxg, gxg, bsm, gsm, aqwj, aqjy]

      if (bName && gName) {
        const d = lockdata(bName, gName).map((item: any, index: number) => {
          item.data = resultList[index]
          return item
        })

        setResultData(d)
        setBName(bName)
        setGName(gName)
        setState({ score })
      }

      delHomePageCouponPop()

      setXNameTest('done')
    } catch (e: any) {
      setState({ errorMsg: e.message || '系统异常，请重试' })
    } finally {
      setState({
        loading: false,
      })
    }
  }

  const onWechatPay = async () => {
    const result = await wxPay(orderId)
    window.location.href = result
  }

  const onAlipay = async () => {
    const result = await aliPay(orderId)

    setAlipayForm(result)

    setTimeout(() => {
      document.forms[0].submit()
    }, 100)
  }

  const toPay = async () => {
    try {
      Loading()
      if (payWay === 'WXPAY') {
        await onWechatPay()
      } else {
        await onAlipay()
      }
    } catch (error: any) {
      Toast(error.message || '系统异常，请重新再试')
    } finally {
      Loading.hide()
    }
  }

  const checkPayStatus = () => {
    const [close] = Alert({
      title: '请确认支付是否完成',
      btnDirection: 'column',
      btns: [
        {
          name: '已完成支付',
          type: 'danger',
          hollow: true,
          onClick: () => {
            fetchMatchResult()
          },
        },
        {
          name: '支付遇到问题，重新支付',
          type: 'danger',
          onClick: () => {
            toPay()
          },
        },
      ],
    })

    alertCloseHandler = close
  }

  useEffect(() => {
    fetchMatchResult()

    return () => {
      timer && clearTimeout(timer)

      alertCloseHandler && alertCloseHandler()
    }
  }, [])

  return (
    <Layout className="page-result" useBottomSafeArea={false}>
      <Layout.Body className="body" loading={state.loading} errorInfo={state.errorMsg}>
        <div className="step">
          <div className="banner">
            <img src={Banner} />
          </div>

          <div className="names">
            <div className="male man">
              <div className="name">{bName}</div>
            </div>
            <div className="heart" />
            <div className="female man">
              <div className="name">{gName}</div>
            </div>
          </div>

          <Title title={`${bName}与${gName}合婚结果`} />

          {state.score > 0 && (
            <div className="score">
              配对评分: <em>{state.score}</em>
            </div>
          )}

          <div className="result-list lock">
            {resultData.map((item: any, key: number) => {
              return <Result title={item.title} key={key} data={item.data} />
            })}
          </div>

          <Button className="submit" onClick={onBackHome}>
            再测试一次
          </Button>

          <div className="ads">
            <ClassifyHeader title="热门推荐" />

            <a className="ad yinyuan" href="https://yanyuan.ijianshui.com/?channel=uyvs$53">
              <img src={AdYinyuan} />
            </a>

            <a className="ad hehun" href="https://hehun.ijianshui.com?channel=x27e$65">
              <img src={AdHehun} />
            </a>

            <a className="ad qinggan" href="	https://qinggan.ijianshui.com?channel=3bvs$70">
              <img src={AdQinggan} />
            </a>
          </div>
        </div>

        <FeedBack orderNo={orderId} />
      </Layout.Body>
      <div
        id="alipay_form"
        style={{ display: 'none' }}
        dangerouslySetInnerHTML={{ __html: alipayForm }}
      />
    </Layout>
  )
}

export default PageIndex
