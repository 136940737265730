export const lockdata = (male = '', female = '') => [
  {
    title: `第一：${male}先生的性格解析`,
  },
  {
    title: `第二：${female}小姐的性格解析`,
  },
  {
    title: `第三：${male}先生的运势`,
  },
  {
    title: `第四：${female}小姐的运势`,
  },
  {
    title: '第五：爱情危机',
  },
  {
    title: '第六：爱情建议',
  },
]
